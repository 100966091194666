import React from 'react';
import Account from './Account';
import CodingTestScreen from './CodingTestScreen';
import Profile from './Profile';
import Screen from './Screen';
import Protected from '../Components/Protected';
import { Routes, Route } from 'react-router-dom';
import Header from '../Header';
import { Dashboard } from './Admin/Dashboard';
import { CandidateAllTest } from './Admin/CandidateAllTest';
import { TestDetails } from './Admin/TestDetails';
import { AdminLogin } from './Admin/AdminLogin';
import { ProfileVerify } from './ProfileVerify';
import { CompleteProfile } from './CompleteProfile';



const Layout = (props) => {

    return (
        <>
            <Header />

            <div className="main-wrapper">
                <div className="main-content">
                    <Routes>
                        {/* <Route path='/' element={<Signin />} /> */}
                        <Route path='/account' element={<Protected><Account /></Protected>} />
                        <Route path='/profile-verify' element={<Protected><ProfileVerify /></Protected>} />
                        <Route path='/codingtest' element={<Protected><CodingTestScreen /></Protected>} />
                        <Route path='/profile' element={<Protected><Profile /></Protected>} />
                        <Route path='/complete-profile' element={<Protected><CompleteProfile /></Protected>} />
                        <Route path='/screen' element={<Protected><Screen /></Protected>} />
                        <Route path='/admin-login' element={<Protected><AdminLogin /></Protected>} />
                        <Route path='/dashboard' element={<Protected><Dashboard /></Protected>} />
                        <Route path='/candidate-tests' element={<Protected><CandidateAllTest /></Protected>} />
                        <Route path='/test-details' element={<Protected><TestDetails /></Protected>} />
                    </Routes>
                </div>
            </div>
        </>
    );
};

export default Layout;


